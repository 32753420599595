import React from 'react';
import {Chip} from "@mui/material";
import {makeStyles} from '@mui/styles';

const styles = makeStyles(() => ({
  root: {
    width: '40px'
  },
  chip: {
    fontSize: '15px',
    margin: 2,
  },
  icon: {
    width: '40px',
    fontSize: '15px',
  },
  avatarChild: {
    width: '12px'
  }
}));

const BasicChip = (props) => {
  const classes = styles();
  const {label, avatar} = props;
  return (
    <span>
        {avatar && <Chip label={label} className={classes.chip}
                         classes={{avatar: classes.icon, avatarChildren: classes.avatarChild}} avatar={avatar}/>}
      {!avatar && <Chip label={label} className={classes.chip}/>}
      </span>
  );
}
export default (BasicChip);
