import React from 'react'
import Carousel from 'nuka-carousel'
import {IconButton} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import {withStyles} from "@mui/styles";

const QuoteIcon = withStyles((theme) => ({
  root: {
    transform: "scaleX(-1)"
  }
}))(FormatQuoteIcon);

export class testimonialsComponent extends React.Component {
  render() {
    return (
      <section className="testimonials-section section">
        <h2 className="section-title">Featured Reviews</h2>
        <div id="testimonials-carousel" style={{maxHeight: "350px"}} className="testimonials-carousel carousel slide">
          <Carousel autoplay={true} autoplayInterval={8000}
                    renderCenterLeftControls={({previousSlide}) => (
                      <IconButton onClick={previousSlide}><ArrowBackIcon/></IconButton>
                    )}
                    renderCenterRightControls={({nextSlide}) => (
                      <IconButton onClick={nextSlide}><ArrowForwardIcon/></IconButton>
                    )}
          >
            <div className="item">
              <blockquote className="quote">
                <p>
                  <QuoteIcon fontSize={"large"}/>
                  Intricate and hard-hitting, Back Bay Murders is an essential crime novel to add to your collection.
                  Bonar will have you cringing—but in that “I can’t look away” sense only a great thriller can produce.
                </p>
              </blockquote>
              <div className="source">
                <div className="name">Alex Melone, TypeRightEditing.com</div>
                <div className="position">Back Bay Murders</div>
              </div>
            </div>
            <div className="item">
              <blockquote className="quote">
                <p><QuoteIcon fontSize={"large"}/>
                  Reminded me of a Patterson book, but with more violence. A thrilling story that was quick to read.</p>
              </blockquote>
              <div className="source">
                <div className="name">Tricia H.</div>
                <div className="position">Back Bay Murders</div>
              </div>
            </div>
            <div className="item active">
              <blockquote className="quote">
                <QuoteIcon fontSize={"large"}/>
                <p>This book scares me. I know Andrew Bonar and was unable to comprehend how he generates this quality
                  material.</p>
              </blockquote>
              <div className="source">
                <div className="name">Krysta B.</div>
                <div className="position">Back Bay Murders</div>
              </div>

            </div>
          </Carousel>
        </div>
      </section>
    );
  }
}

export default testimonialsComponent;
