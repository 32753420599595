import React from 'react';
import BasicChip from './BasicChip'
import {AlertDecagram} from 'mdi-material-ui'
import Kindle from '../img/kindle-logo.png'
import {styled} from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Avatar, Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  IconButton,
  List,
  ListItem, Stack,
  Typography
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {red} from "@mui/material/colors";

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '20px'
  },
  chip: {
    fontSize: '15px'
  },
  card: {},
  media: {
    height: '100%',
    paddingTop: '150%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  avatarIcon: {
    backgroundColor: '#00BCD4'
  },
  avatar: {
    backgroundColor: red[500],
  },
  buttons: {
    margin: 5
  }
}));

const ExpandMore = styled((props) => {
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const Book = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const classes = styles();

  const handleExpandClick = () => {
    setExpanded((old) => !old);
  };

  const getAvatar = (genre, bookId) => {
    switch (genre.toLowerCase()) {
      case 'thriller':
        return <Avatar key={bookId + genre}></Avatar>
      case 'crime':
        return <Avatar key={bookId + genre}></Avatar>
      case 'mystery':
        return <Avatar key={bookId + genre}></Avatar>
      default:
        return <Avatar></Avatar>
    }
  }


  const {book} = props;
  return (
    <div style={{width: '90%', minWidth: 300, margin: '0 auto'}}>
      <Card className={classes.card}>
        <CardHeader
          classes={{title: classes.title}}
          avatar={
            <Chip
              avatar={
                <Avatar>
                  <AlertDecagram/>
                </Avatar>
              }
              label={book.rating}
              className={classes.chip}
              color="secondary"
            />
          }
          action={
            <Typography variant={"button"}>Released {book.released}</Typography>
          }
        />
        <CardMedia
          className={classes.media}
          image={book.img}
          title="Back Bay Murders"
        />
        <CardContent classes={{root: classes.root}}>
          {book.genre.map((label, index) =>
            <BasicChip key={index} label={label} avatar={getAvatar(label)}/>
          )}
        </CardContent>
        <CardContent>
          <div style={{width: "100%"}}>
            <Box display={"flex"} justifyContent={"center"}>
              <Stack>
                <Typography variant={"h4"}>{book.title}</Typography>
                <Box display={"flex"} justifyContent={"center"}>
                  <Typography variant={"h6"}>By {book.author}</Typography>
                </Box>

              </Stack>
            </Box>
          </div>
        </CardContent>
        <CardContent>
          <List style={{margin: '0 auto', width: '50%'}}>
            <ListItem>
              <div style={{width: "100%"}}>
                <Box justifyContent={"center"} display={"flex"}>
                  <Button className={classes.buttons} onClick={() => window.location.replace(book.kindle)}>
                    <img src={Kindle} alt="kindle" height={30}/>
                  </Button>
                </Box>
              </div>
            </ListItem>
          </List>
        </CardContent>
        <CardActions className={classes.actions}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon/>
          </ExpandMore>
          {!expanded && <Button variant={"text"} onClick={() => setExpanded((old) => !old)}>Learn More</Button>}
          {expanded && <Button variant={"text"} onClick={() => setExpanded((old) => !old)}>Hide More</Button>}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph variant="h4">
              Synopsis
            </Typography>
            <Typography paragraph variant='h5' style={{whiteSpace: 'pre-wrap'}}>{book.desc}</Typography>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
export default Book;
