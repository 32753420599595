import React from 'react'
import {BOOKS} from "../data/project_metadata"
import Book from "../components/Book"
import Grid from '@mui/material/Grid'

export class portfolioComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      activePortfolio: BOOKS,
      children: BOOKS,
      filtered: false
    };
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.filterChildren = this.filterChildren.bind(this);
  }

  filterChildren() {
    if (this.state.filtered === false) {
      let newChildren = this.state.children.filter(function (child, index) {
        if (index % 2 === 0) {
          return child
        }
        return null
      });
      this.setState({
        children: newChildren,
        filtered: true
      });
    } else {
      this.setState({
        children: BOOKS,
        filtered: false
      });
    }
  }

  handleTypeChange(e) {
    let id = e.target.id;
    if (id !== this.state.activePortfolio) {
      this.setState({
        activePortfolio: id
      })
    }
  }

  render() {
    return (<div>
        <h2 className="section-title">Books</h2>
        <Grid container spacing={4}>
          {BOOKS.map(book => {
            return <Grid item xs key={book.bookId}><Book book={book}/></Grid>
          })}
        </Grid>
      </div>
    );
  }
}

export default portfolioComponent;
