import backBayMurders from '../img/portfolio/1.jpg'
import fragmentMurder from '../img/fragment-murder.jpeg'

export const BOOKS = [
  {
    bookId: 1,
    author: 'Andrew Bonar',
    title: 'Back Bay Murders',
    desc: 'Part of him longed for it to happen. What would the media call him? Killer of the Charles? Murderer on the Charles? The Back Bay Murderer? How many more could he kill before they caught on? Year after year, there was nothing on TV besides, “Another accidental drowning on the Charles.”\n' +
      '\n' +
      'At only sixteen, Jack finds himself in an ultimatum—join a clandestine organization or be turned in to the police. While the organization upholds a strict code of conduct, Jack can’t fight his sociopathic urges, a reason the organization picked him up in the first place. On the other side of the Charles, Drew, a gritty, discreet private investigator, is hired to probe into the drowning of a young man. Upon examining the young man’s body at the Boston morgue, he discovers someone else is interested in John Doe 2199—Ashley Tinder. Drew realizes there’s something deeper going on than just a series of murders as he untangles Ashley’s involvement.\n' +
      '\n' +
      'Intricate and hard-hitting, Back Bay Murders is an essential crime novel to add to your collection. Bonar will have you cringing—but in that “I can’t look away” sense only a great thriller can produce. ~Alex Melone, TypeRightEditing.com',
    kindle: 'https://www.amazon.com/dp/B07R1X2RSW',
    genre: ['Thriller', 'Crime', 'Mystery'],
    img: backBayMurders,
    rating: 'Mature',
    released: "04/2019"
  },
  {
    bookId: 2,
    author: 'Andrew Bonar',
    title: 'The Fragment Murder',
    desc: 'The Fragment Murder\n' +
      'By Andrew Bonar\n' +
      '\n' +
      'With the Back Bay Murders squarely behind them, Drew Law and Ashley Tinder set up shop as quiet private investigators in the suburbs of Boston, but little do they know, the Organization is not done with them yet. Their firm gets a call one day from a woman seeking help solving the sudden death of her granddaughter and makes Drew and Ashley an offer they can’t refuse.\n' +
      '\n' +
      'Meanwhile, the daughter of the Organization’s head, Elle, has a hit ordered against her from the inside and must escape. Fueled by anger over her father, she takes matters into her own hands to bring the Organization down—but she can’t do it alone and pulls in the only two people who can help her, Drew, and Ashley.\n' +
      '\n' +
      'Worlds collide as deeper truths are uncovered about the Organization and its motivations. A breakthrough in a formidable technology kept secret for decades can now aid them in their tireless hunt for relics of bygone eras that act as keys to gaining them entry into a deeper underground group, the Royals.\n' +
      '\n' +
      '"Fast-paced, gritty, and entertaining, this newest book in the Drew Law series by Andrew Bonar will keep you on your toes with twists and turns at every corner." ~Alex Dallman',
    kindle: 'https://www.amazon.com/dp/B0BCZN5249',
    genre: ['Thriller', 'Crime', 'Mystery'],
    img: fragmentMurder,
    rating: 'Mature',
    released: "09/2022"
  }
];

export const EMAIL = 'contact@andrewbonar.com';
