import React from 'react'
import Scroll from 'react-scroll'

import Testimonials from './Sections/Testimonials'
import Projects from './Sections/Projects'
import Contact from "./Sections/Contact";

let Element = Scroll.Element;

class homeBodyComponent extends React.Component {
  render() {
    return (
      <div className="wrapper container">
        <Element name="portfolio-section">
          <Projects/>
        </Element>
        <Element name="testimonials-section">
          <Testimonials/>
        </Element>
        <Element name="testimonials-section">
          <Contact/>
        </Element>
      </div>
    );
  }
}

export default homeBodyComponent;
