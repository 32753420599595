import React, {Component} from 'react';
import Scroll from 'react-scroll';

import HomeBody from './HomeBody';
import Name from './components/NameComponent';
import DateComponent from './components/DateComponent';
import EmailComponent from './components/EmailComponent';
import {EMAIL} from './data/project_metadata'
import profile from './img/profile-image-ab.png'
import SiteMap from './docs/sitemap.xml'
import {FaAmazon, FaFacebook, FaGoodreads, FaMailBulk, FaNewspaper, FaSlack} from 'react-icons/fa';
import Mail from "@mui/icons-material/Mail";

import Public from "@mui/icons-material/Public";
import {Tooltip} from "@mui/material";

let ScrollLink = Scroll.Link;
let Events = Scroll.Events;
let scrollSpy = Scroll.scrollSpy;

class App extends Component {

  componentDidMount() {
    Events.scrollEvent.register('begin', function () {
    });
    Events.scrollEvent.register('end', function () {
    });
    scrollSpy.update();
  }

  static componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  render() {
    return (<div className="App-header">
      <header className="header">
        <div className="top-bar container-fluid">
          <ul className="social list-inline">
            <li>
              <Tooltip title={"Facebook"}><a href="https://www.facebook.com/BonarAndrew"><FaFacebook
                fontSize={"x-large"}/></a></Tooltip>
            </li>
            <li><Tooltip title={"Email"}><a href={`mailto:${EMAIL}`}><FaMailBulk fontSize={"x-large"}/></a></Tooltip>
            </li>
            <li>
              <Tooltip title={"Newsletter Signup"}>
                <a href={"#"} onClick={() => global.ml('show', 'SW70B8',
                  true)}><FaNewspaper fontSize={"x-large"}/></a></Tooltip></li>
            <li>
              <Tooltip title={"Slack Chat and Discussion"}>
                <a
                  href={"https://join.slack.com/t/slack-uyr7898/shared_invite/zt-1k1n5a5j8-wMSn9kSOGsEjzL7Fg~XpIg"}><FaSlack
                  fontSize={"x-large"}/></a></Tooltip></li>
            <li>
              <Tooltip title={"Amazon Author Page"}>
                <a
                  href={"https://www.amazon.com/Andrew-Bonar/e/B07QWM7KNC"}><FaAmazon
                  fontSize={"x-large"} /></a></Tooltip></li>
            <li>
              <Tooltip title={"Goodreads Author Page"}>
                <a
                  href={"https://www.goodreads.com/author/show/22348741.Andrew_Bonar"}><FaGoodreads
                  fontSize={"x-large"}/></a></Tooltip></li>

          </ul>

        </div>
        <div className="intro">
          <div className="container text-center">
            <img className="profile-image" src={profile} alt="img"/>
            <h1 className="name"><Name/></h1>
            <div className="title">Author</div>
            <div className="profile">
              <p>
                Andrew Bonar is an author, engineer, and entrepreneur telling raw, gripping and compelling stories. A
                longtime New Hampshire USA resident, often featuring New England locale in his works. Andrew graduated
                from the University of Massachusetts, Amherst with a B.S. in Electrical Engineering and works as a
                Software Engineer when not writing fiction novels.
              </p>
              <p>
                Andrew comes from a long line of published authors, a natural story teller, who carefully crafts
                intricate plots, and expertly captures character nuances. Andrew is married and has three young children
                -
                Jem, Madison, and Cooper.
              </p>
            </div>
          </div>
        </div>
        <div className="contact-info">
          <div className="container text-center">
            <ul className="list-inline">
              <li className="email">
                <Mail fontSize={"large"}/>
                <EmailComponent/>
              </li>
              <li className="website">
                <Public fontSize={"large"}/>
                <a href="/" target="_blank">andrewbonar.com</a></li>
            </ul>
          </div>
        </div>

        <div className="page-nav-space-holder hidden-xs">
          <div id="page-nav-wrapper" className="page-nav-wrapper text-center">
            <div className="container">
              <ul id="page-nav" className="nav page-nav list-inline">
                <li><ScrollLink activeClass="active"
                                className="scrollto"
                                to="portfolio-section"
                                spy={true}
                                smooth={true}
                                duration={500}>Books</ScrollLink></li>
                <li><ScrollLink activeClass="active"
                                className="scrollto"
                                to="testimonials-section"
                                spy={true}
                                smooth={true}
                                duration={500}>Featured Reviews</ScrollLink></li>
                <li><ScrollLink activeClass="active"
                                className="scrollto"
                                to="contact-section"
                                spy={true}
                                smooth={true}
                                duration={500}>Contact</ScrollLink></li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <HomeBody name="body content"/>

      <footer className="footer text-center">
        <div className="container">
          <small className="copyright">Copyright &copy; <DateComponent type="year"/>
            <Name type="href"/> | <a href={SiteMap}>Site map</a>
          </small>
        </div>
      </footer>
    </div>);
  }
}

export default App;
